import React, { useContext } from "react";
import DNavbar from "./DNavbar";
import { Outlet, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { AuthContext } from "../../context/AuthContext";

function Dashboard() {
  const { login  } = useContext(AuthContext);

  return (
    <>
      {login.isloggedin ? (
        <>
          <DNavbar />
          <Outlet />
        </>
      ) : 
    null
    }
    </>
  );
}

export default Dashboard;
